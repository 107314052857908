
const initSlider = (el, opts) => {
    const swiper = new Swiper(el, {
        ...opts,
    });

    if(swiper.slides.length < 4) {
        swiper.disable();
    }

    return swiper;
}

const swipers = document.querySelectorAll('.swiper-single');
swipers.forEach((slider)=> {
    const swiper = initSlider(slider,{
        direction: 'horizontal',
        loop: true,
        speed: 800,
        autoplay: {
            delay: 7000,
        },
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: slider.querySelector('.slider-buttons__next'),
            prevEl: slider.querySelector('.slider-buttons__prev'),
        },
    })
})

const swiperOffers = initSlider('.swiper-offers', {
    slidesPerView: 3,
    spaceBetween: 57,
    navigation: {
        nextEl: '.swiper-offers .slider-buttons__next',
        prevEl: '.swiper-offers .slider-buttons__prev',
    },
    loop: true,
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        760: {
            slidesPerView: 2,
        },
        1200: {
            slidesPerView: 3,
        }
    }
})

const swiperRooms = initSlider('.swiper-rooms', {
    slidesPerView: 2,
    spaceBetween: 15,
    navigation: {
        nextEl: '.swiper-rooms .slider-buttons__next',
        prevEl: '.swiper-rooms .slider-buttons__prev',
    },
    loop: true,
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        900: {
            slidesPerView: 2,
        }
    }
})

const swiperRoomGallery = initSlider('.swiper-gallery', {
    slidesPerView: 3,
    spaceBetween: 15,
    navigation: {
        nextEl: '.swiper-gallery .slider-buttons__next',
        prevEl: '.swiper-gallery .slider-buttons__prev',
    },
    loop: false,
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        760: {
            slidesPerView: 2,
        },
        1200: {
            slidesPerView: 3,
        }
    }
})